@import url(https://fonts.googleapis.com/css?family=Fira+Sans);
body {
  margin: 0;
  font-family: "Fira Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: -webkit-gradient(
    linear,
    right top, left top,
    from(rgba(7, 27, 82, 1)),
    to(rgba(0, 128, 128, 1))
  );
  background: linear-gradient(
    to left,
    rgba(7, 27, 82, 1) 0%,
    rgba(0, 128, 128, 1) 100%
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card-container {
  display: flex;
  flex-direction: column;
  background-color: #95dada;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 25px;
  cursor: pointer;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-transition: -webkit-transform 0.25s ease-out;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.card-container:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.card-list {
  width: 85vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.search {
  -webkit-appearance: none;
  font-size: 18px;
  border: none;
  border-bottom: 1px solid white;
  outline: none;
  padding: 10px;
  width: 180px;
  line-height: 30px;
  margin-bottom: 30px;
  background: transparent;
  color: white;
}

::-webkit-input-placeholder { /* Edge */
  color: gray;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: gray;
}

::-moz-placeholder {
  color: gray;
}

::-ms-input-placeholder {
  color: gray;
}

::placeholder {
  color: gray;
}

.App {
  text-align: center;
}

h1 {
  font-family: 'Bad Script', cursive;
  font-size: 72px;
  color: #0ccac4;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

